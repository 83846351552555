<template>
  <div v-if="show">
    <vue-headful :title="pageTitle"/>
    <div id="body-content-area-all" style="overflow: scroll">
      <div class="has-text-centered heading" :style="{ backgroundColor: '#ededed', marginBottom: '0px'}">
        <h1 class="is-size-6" style="padding: 10px 0px"><b>{{ pageName.toLocaleUpperCase() }}</b></h1>
      </div>
      <div id="pagelayout" style="padding: 25px;">
        <small style="color: #eaae5e!important; padding:5px; margin-top: 20px;">Please use a modern browser to upload files.</small>
        <Grid
          style="height: 100%;"
          v-if="documents"
          :filter="filter"
          :data-items="documents"
          :sortable="true"
          :sort="sort"
          :filterable="false"
          :columns="staticColumns"
          :skip="skip"
          :take="take"
          :total="totalRecords"
          :pageable="pageable"
          @selectionchange="selectionchange"
          @headerselectionchange="headerselectionchange"
          @filterchange="filterchange"
          @sortchange="sortchange"
          @pagechange="pagechange"
          >
            <template v-slot:NameCell="{props}">
              <td class="editable" style="text-overflow: clip!important;">
                <input v-if="isOwnedByUser(props.dataItem)" type="text" v-on:blur="submit($event, props.dataItem, 'displayName')" v-on:keyup.esc="reset($event, props.dataItem.displayName)" v-on:keyup.enter="submit($event, props.dataItem, 'displayName')" :value="props.dataItem.displayName">
                <input v-else type="text" @mousedown.prevent="" :value="props.dataItem.displayName">
              </td>
            </template>

            <template v-slot:TypeCell="{props}">
              <td>
                {{props.dataItem.extension.replace('.', '').toUpperCase()}}
              </td>
            </template>

            <template v-slot:SizeCell="{props}">
              <td style="text-align: right;">
                {{ getFileSize(props.dataItem.fileSize) }}
              </td>
            </template>

            <template v-slot:UploadDateCell="{props}">
              <td>
                {{ props.dataItem.uploadDate| moment('timezone', getTimeZone(), 'MM/DD/YYYY') }}
              </td>
            </template>

            <template v-slot:UploadedByCell="{props}">
              <td>
                {{ props.dataItem.uploadUserName }}
              </td>
            </template>

            <template v-slot:SharingCell="{props}">
              <td class="editable" v-if="isOwnedByUser(props.dataItem)">
                <select @change="submit($event, props.dataItem, 'isShared');">
                    <option :value="true" :selected="props.dataItem.isShared">Shared</option>
                    <option :value="false" :selected="!props.dataItem.isShared">Private</option>
                </select>
              </td>
              <td class="editable" v-else>
                <select @mousedown.prevent="" ref="isSharedDropdown">
                    <option :selected="true">{{props.dataItem.isShared ? 'Shared' : 'Private'}}</option>
                </select>
              </td>
            </template>

            <template v-slot:ActionCell="{props}">
              <td style="width: 100%; padding: 0;">
                <div class="action-cell">
                  <div v-tooltip.left-start="{ content: 'Download Document', container: false, classes: ['tooltip'] }">
                    <a :href="getDocumentPath(props.dataItem)" download><i class="fa fa-download"></i></a>
                  </div>
                  <div v-tooltip.left-start="{ content: 'View Document', container: false, classes: ['tooltip'] }">
                    <a href="" @click.prevent="viewDocument(props.dataItem)"><i class="fa fa-eye"></i></a>
                  </div>
                  <div v-tooltip.left-start="{ content: 'Delete Document', container: false, classes: ['tooltip'] }">
                    <a v-if="isOwnedByUser(props.dataItem)" href="" @click.prevent="deleteDocument(props.dataItem)"><i class="fa fa-trash"></i></a>
                  </div>
                </div>
              </td>
            </template>
        </Grid>
      </div>

      <!-- Delete modal: // TODO [BD|05-2021] Wire this up to prompt the user to delete the items -->
      <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
          <div class="modal-background" @click="showDeleteModal = false"></div>
          <div class="modal-card">
          <header class="modal-card-head has-bg-danger">
              <div class="modal-card-title has-bg-danger">Confirm Delete</div>
              <a class="delete" aria-label="close" @click="showDeleteModal = false"></a>
          </header>
          <section class="modal-card-body">
              <div class="content">
              Type "<b>delete</b>" to confirm that you want to delete "{{ selectedFile.name }}"
              </div>
              <div class="field">
              <div class="control">
                  <input
                  type="text"
                  class="input"
                  placeholder="type `delete` and then click confirm"
                  v-focus
                  v-model.trim="confirmationText"
                  @keydown.enter.prevent="deleteIfConfirmed"
                  />
              </div>
              </div>
          </section>
          <footer class="modal-card-foot">
              <a
              class="button is-danger"
              @click="deleteIfConfirmed"
              :disabled="confirmationText !== 'delete'"
              >
              <span class="icon">
                  <i class="fal fa-trash-alt"></i>
              </span>
              <span>Confirm</span>
              </a>
              <a class="button" @click="showDeleteModal = false">Cancel</a>
          </footer>
          </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Loading/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import { Grid } from '@progress/kendo-vue-grid'
import Loading from './Loading'

import debounce from 'debounce'

export default {
  name: 'IEBankUploadsScreen',
  props: {
    query: String
  },
  components: {
    'Grid': Grid,
    Loading
  },
  data () {
    return {
      show: false,
      confirmationText: null,
      isSaving: false,
      reportTypeCodes: null,
      documents: null,
      input: {
        id: '',
        name: '',
        displayName: '',
        typeCode: '',
        typeCodeDescription: '',
        institutionId: '',
        institutionName: '',
        yearOverride: '',
        extension: '',
        uploadDate: '',
        fileSize: 0,
        sendEmail: false,
        emailTemplateId: 0,
        files: []
      },
      showDeleteModal: false,
      props: {
        grid: Grid,
        field: String,
        filterType: String,
        value: [String, Number, Boolean, Date],
        operator: String
      },
      skip: 0,
      take: 20,
      pageSize: 20,
      perPage: 20,
      page: 1,
      institution: null,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      },
      staticColumns: [
        {
          field: 'id',
          title: 'Id',
          filterable: false,
          hidden: true
        },
        {
          field: 'displayName',
          title: 'Document Name',
          width: '320px',
          cell: 'NameCell'
        },
        {
          field: 'extension',
          title: 'Type',
          width: '130px',
          cell: 'TypeCell'
        },
        {
          field: 'fileSize',
          title: 'Size',
          cell: 'SizeCell'
        },
        {
          field: 'uploadDate',
          title: 'Uploaded On',
          cell: 'UploadDateCell'
        },
        {
          field: 'uploadUserName',
          title: 'Uploaded By',
          cell: 'UploadedByCell'
        },
        {
          field: 'isShared',
          title: 'Sharing',
          cell: 'SharingCell'
        },
        {
          cell: 'ActionCell',
          width: '150px'
        }
      ],
      sort: [
        { field: 'name', dir: 'asc' }
      ],
      filter: {
        logic: 'and',
        filters: [
        ]
      }
    }
  },
  computed: {
    ...mapState(['clientSession', activeSite]),
    pageTitle () {
      if (this.institution) {
        return (this.pageName + ' - ' + this.institution.name + ' ' + (parseInt(this.activeSite.id) === 1 ? 'BOLI Portal' : 'COLI Portal'))
      }

      return ''
    },
    isIE () {
      var ua = navigator.userAgent
      return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1
    }
  },
  async created () {
    if (this.activeSite.id === 1) {
      this.pageName = 'Bank Uploads'
    } else {
      this.pageName = 'Business Uploads'
    }

    await this.GetInstitution()
    await this.getReportTypes()
  },
  mounted () {
    this.getDocuments()
  },
  methods: {
    async getReportTypes () {
      const that = this
      this.axios.get(`/memberships/${this.clientSession.userId}`)
        .then(function (response) {
          if (response.status === 200) {
            that.reportTypeCodes = response.data.reportTypeCodes

            if (!that.reportTypeCodes.includes('USR')) {
              that.$router.push({ name: 'Dashboard' })
            }

            that.show = true
          }
        })
    },
    openFileBrowser () {
      debounce(this.$refs.filePond.browse(), 500, false)
    },
    async GetInstitution () {
      try {
        let that = this
        this.isLoading = true
        let route = `/institutions/${this.clientSession.institutionId}/`
        this.axios.get(route)
          .then(function (response) {
            if (response.status >= 200 && response.status < 300) {
              that.institution = response.data
            }
          })
      } catch (error) {
        this.handleApiErr(error)
      }
    },
    async getDocuments () {
      let response = await this.axios.get(`documents/clients?page=${(this.skip / this.take) + 1}&perPage=${this.take}&sortBy=${this.sort[0].field}&sortDirection=${this.sort[0].dir}`)
      if (response.status === 200) {
        this.documents = response.data.records
        this.totalRecords = response.data.page.totalRecords
      }
    },
    selectionchange () {
      // stuff
    },
    headerselectionchange () {
      // stuff
    },
    filterchange () {
      // stuff
    },
    sortchange (e) {
      this.sort = e.sort
      this.getDocuments()
    },
    async submit (event, original, type) {
      if (event.target.value !== original[type]) {
        original[type] = event.target.value

        let payload = new FormData()
        let keys = Object.keys(original)

        keys.forEach(function (key) {
          if (key === 'uploadDate') {
            var result = new Date(original[key])
            result.setDate(result.getDate() + 1)
            payload.append(key, result.toDateString())
          } else {
            payload.append(key, original[key])
          }
        })

        let response = await this.axios.put('documents/clients/' + original.id, payload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        if (response.status >= 200 && response.status < 300) {
          this.getDocuments()
        }
      }

      this.$forceUpdate()
      event.target.blur()
    },
    reset (event, value) {
      event.target.value = value
      event.target.blur()
    },
    pagechange (e) {
      this.take = e.page.take
      this.skip = e.page.skip

      this.getDocuments()
    },
    isOwnedByUser (file) {
      return parseInt(file.uploadUserId) === parseInt(this.clientSession.userId)
    },
    viewDocument (document) {
      this.$router.push({
        name: 'ViewDocumentScreen',
        params: {
          id: document.id,
          document: document
        }
      })
    },
    deleteDocument (document) {
      this.selectedFile = document
      this.showDeleteModal = true
    },
    async deleteIfConfirmed () {
      let response = await this.axios.delete('documents/clients/' + this.selectedFile.id)

      if (response.status >= 200 && response.status < 300) {
        this.showDeleteModal = false
        this.getDocuments()
      }
    }
  }
}
</script>

<style scoped>
.filepond--root {
  height: 500px!important;
}
  .add-button-container {
    display: inline-block;
    width: 100%;
    position: relative;
    padding-bottom: 1rem;
  }

  .add-button {
    float: right;
    background: #7fb841;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 4px 1px #AEAEAE;
    box-shadow: 0px 0px 4px 1px #AEAEAE;
    color: white;
    padding: 4px 10px 6px 8px;
    cursor: pointer;
  }

  .add-button:hover {
    background: #93c65d;
  }

  .add-button i.fa {
    font-size: 12px!important;
    margin: auto;
  }

  .editable:hover {
    -webkit-box-shadow: 0px 0px 4px 1px #AEAEAE;
    box-shadow: 0px 0px 4px 1px #AEAEAE;
    cursor: pointer;
  }

  .editable button {
    visibility: hidden;
    background: white;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    color: #7fb841;
    padding: 5px;
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .saving {
    background: #d9d9d9!important;
  }

  .editable input {
    width: 100%;
  }

  .editable select {
    width: 100%;
  }

  .editable input:focus ~ button {
    visibility: visible;
  }

  select {
    appearance: none;
    padding: 5px;
    border: none;
    font-family: inherit;
    outline: none;
  }

  .editable input, .editable select {
    background-color: transparent;
    border: 0px;
    cursor: pointer;
  }

  .editable input:focus {
    outline: none;
    cursor: text;
  }

  .action-cell {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 25px;
  }

  @media (min-width: 800px) {
    td .action-cell a {
      display: none;
      margin: 0 .25rem;
    }
  }

  tr:hover .action-cell a {
    display: inline-block;
    transition: all 1s ease-out;
  }

  .filepond--drop-label {
    display: none!important;
  }

  .filepond--credits {
    display: none!important;;
  }

  .filepond--root {
    height: 500px!important;
  }

  .filepond--panel-root {
    background-color: white!important;
  }

  i.fas .drop {
    font-size: 3rem !important;
    min-width: 50px !important;
    max-width: 50px !important;
    padding: 0px !important;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .modal-footer > button {
    padding: .5rem;
    background: #7fb942;
    color: white;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif!important;
  }

  .link:hover {
    color: #9cbd78;
    cursor: pointer;
  }

  .modal-footer > button:hover {
    background: #9cbd78;
  }
</style>

<style>
  .tooltip {
    font-size: 12px!important;
    padding: 5px!important;
  }
.filepond--panel-root {
    background-color: #eee!important;
}

.filepond--drop-label {
  font-family: 'Poppins', sans-serif!important;
    position: absolute;
    bottom: 0;
    top: auto;
    padding-bottom: 3rem;
}

.filepond--list-scroller {
  position: absolute;
  top: 0;
  transform: translate3d(0px, 0px, 0px)!important;
}

.filepond--drip-blob {
    background-color: #999;
}
/* bordered drop area */
.filepond--panel {
    background-color: #fff!important;
    border: 2px solid #fff;
}
</style>
